import React from 'react'
import Image from 'gatsby-image'
import {graphql, StaticQuery, Link} from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import Page from '../components/page/contact-page'

import styles from '../components/home.module.css'

export default class Index extends React.Component {
  render () {
    return (

      <StaticQuery
        query={graphql`
            query sanityContact {
              sanityContact {
                title
                _rawBody
                mainImage {
                  asset {
                    fluid(maxWidth:1900) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                formSettings {
                  thankyou
                  emailto
                  subject
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
        render={data => (
          <Layout mainImage={data.sanityContact.mainImage} title={data.sanityContact.title}>
            {data.sanityContact.seoSettings.title && data.sanityContact.seoSettings.description &&
              (<SEO title={data.sanityContact.seoSettings.title} description={data.sanityContact.seoSettings.description} />
              )}
            <Page
              title={data.sanityContact.title}
              _rawBody={data.sanityContact._rawBody}
              thankYou={data.sanityContact.formSettings.thankyou}
              emailto={data.sanityContact.formSettings.emailto}
              subject={data.sanityContact.formSettings.subject}
            />
          </Layout>
        )}
      />

    )
  }
}
